<template>
  <div pt="8" bg="#F6F6F6" b-t="1 solid #00000014">
    <div max="w-300" m="x-auto" flex="~ justify-between">
      <img src="~/assets/icons/azgoxplore-green.png" h="10" mr="60" />
      <div>
        <div text="lg #121212" m="b-4">
          {{ t('key.footer.company') }}
        </div>
        <ul class="space-y-3" text="sm" font="400">
          <li v-for="link in aboutLinks" :key="link.text">
            <NuxtLink :to="link.url">
              <span class="text-#808080" font="300">{{ link.text }}</span>
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div text="lg #121212">
        <div m="b-4">
          {{ t('key.footer.help') }}
        </div>
        <ul class="space-y-3" text="sm" font="400">
          <li>
            <NuxtLink to="https://corporate.azgotrip.com/contact-us" class="text-#808080 hover:text-gray-900">
              <span class="text-#808080" font="300">{{ t('key.footer.contact.us') }}</span>
            </NuxtLink>
          </li>
          <li v-if="!isBurnCoinsScene">
            <NuxtLink to="https://corporate.azgotrip.com/faq" target="_self" class="text-#808080 hover:text-gray-900">
              <span class="text-#808080" font="300">{{ t('key.footer.faqs') }}</span>
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div class="text-lg text-#121212">
        <div m="b-4">
          {{ t('key.footer.partnerships') }}
        </div>
        <ul class="space-y-3" text="sm" font="400">
          <li v-for="link in partnershipsLinks" :key="link.text">
            <NuxtLink :to="link.url" class="text-gray-700 hover:text-gray-900">
              <span text="#808080" font="300">{{ link.text }}</span>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>

    <div mt="8" class="h-1px bg-#00000014"></div>

    <!-- Footer Section -->
    <footer py="8" max="w-300" m="x-auto" font-300 flex="~ justify-between items-center">
      <div text="#808080 sm">
        {{ t('key.footer.copyright') }}
      </div>
      <div>
        <NuxtLink to="https://corporate.azgotrip.com/terms-of-use" :external="true">
          <span class="text-#808080">{{ t('key.footer.terms') }}</span>
        </NuxtLink>
        <span mx="2">·</span>
        <NuxtLink to="https://corporate.azgotrip.com/privacy-policy" :external="true">
          <span class="text-#808080">{{ t('key.footer.privacy.policy') }}</span>
        </NuxtLink>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { isBurnCoinsScene } = useGlobalStore()

const aboutLinks = computed(() => [
  { text: t('key.footer.about.us'), url: 'https://corporate.azgotrip.com/about-us' },
  { text: t('key.footer.products'), url: 'https://corporate.azgotrip.com/products' },
  { text: t('key.footer.press'), url: 'https://corporate.azgotrip.com/press-media-coverage' },
])

const partnershipsLinks = computed(() => [
  { text: t('key.footer.solutions'), url: 'https://corporate.azgotrip.com/solutions' },
  { text: t('key.footer.become.partner'), url: 'https://corporate.azgotrip.com/partnership-enquiry' },
  { text: t('key.footer.become.distributor'), url: 'https://corporate.azgotrip.com/affiliate-program' },
])
</script>

<style lang="scss">
.az-collapse {
  .van-cell {
    padding: 10px 0;
  }

  .van-collapse-item {
    &__content {
      padding: 0 0 12px 0;
    }

    &__title--expanded:after {
      display: none;
    }

    &--border:after {
      left: 0;
      right: 0;
    }
  }
}
</style>
